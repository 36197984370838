import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, lazy } from "react";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import "react-modal-video/css/modal-video.min.css";
import { BrowserRouter } from "react-router-dom";
import ErrorFallback from "./components/error-boundary";
import Spinner from "./components/spinner";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const App = lazy(() => import("./App"));
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    <QueryClientProvider client={queryClient}>
      <ClearBrowserCacheBoundary auto duration={60000}>
        <BrowserRouter>
          <Suspense fallback={<Spinner />}>
            <App />
          </Suspense>
        </BrowserRouter>
      </ClearBrowserCacheBoundary>
    </QueryClientProvider>
  </ErrorBoundary>
);
serviceWorkerRegistration.register();
reportWebVitals();
