import React from "react";
// import { InfinitySpin } from "react-loader-spinner";
import afroSpinner from "../images/afro-spinner.gif";

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {/* <InfinitySpin width="200" color="#f49807" /> */}
      <img src={afroSpinner} width="200" className="" alt="" />
    </div>
  );
};

export default Spinner;
