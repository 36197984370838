import errorImage from "../images/500-error-page.jpg";

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div
      role="alert"
      className="d-flex justify-content-center vh-100 align-items-center"
    >
      <div className="text-center">
        <div style={{ height: "auto", width: "300px" }} className="mb-5">
          <img src={errorImage} alt="error-page" className="img-fluid" />
        </div>

        <button
          className="bg-american-yellow inline-block py-3 rounded-3 mt-5 px-5 border-0 text-white gilroy-bold fs-20"
          onClick={resetErrorBoundary}
        >
          Try again
        </button>
      </div>
    </div>
  );
}

export default ErrorFallback;
